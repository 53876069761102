import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';

import MemoForm from './MemoForm';
import MemoFormTextAreaStyle from './MemoFormTextAreaStyle';

import { phoneNumberDash } from '../../../common';

const OrderDetailStyle = () => {
    const { order } = useSelector((state) => state.order);

    const getProductAllMoney = (order) => {
        if(order) {
            let price = 0;
            for(let orderDetail of order.detail) {
                price = price + (orderDetail.price * orderDetail.qty);
            }
            return price;
        }

        return 0;

    };

    const getDeliveryMoney = (order) => {
        if(order) {
            let price = 0;
            const productAllMoney = getProductAllMoney(order);
            price = productAllMoney === 0 ? 0 : order.deliveryPrice; //배송비
            return price;
        } else {
            return 0;
        }

    };

    const getAddDeliveryPrice = (order) => {
        if(order) {
            let price = 0;
            const productAllMoney = getProductAllMoney(order);
            price = productAllMoney === 0 ? 0 : order.addDeliveryPrice; //배송비
            return price;
        } else {
            return 0;
        }

    };

    const getCancelPrice = (order) => {
        if(order) {
            let cancelPrice = 0;
            for(let payCancel of order.payCancelLog) {
                cancelPrice += Number(payCancel.cancelPrice);
            }
            return cancelPrice;
        }
        return 0;
    };

    const getTotalMoney = (order) => {
        if(order) {
            let price = 0;
            const productAllMoney = getProductAllMoney(order);
            const cancelPrice = getCancelPrice(order);
            price = order.price >= order.brand.freeShippingStandard ? order.price-cancelPrice : order.price+order.deliveryPrice+order.addDeliveryPrice-cancelPrice;

            return price;
        }

        return 0;

    };

    return (
        <div className="uk-margin-small-top uk-padding-small-top uk-padding-small-horizontal uk-text-0_9">
            <div className="uk-grid-divider uk-grid-collapse" data-uk-grid>
                <div className="uk-width-1-1 uk-width-1-2@m">
                    <label className="uk-form-label uk-text-muted uk-text-0_8">주문번호</label>
                    <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                        {
                            order && order.seq && (
                                <div>
                                    <p className="uk-margin-remove">{order.seq}</p>
                                </div>
                            )
                        }
                    </div>
                    <label className="uk-form-label uk-text-muted uk-text-0_8">주문자</label>
                    <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                        {
                            order && order.buyer && (
                                <div>
                                    <p className="uk-margin-remove">{order.buyer.name} / {phoneNumberDash(order.buyer.phone)}</p>
                                    <p className="uk-margin-remove">{order.buyer.email}</p>
                                </div>
                            )
                        }
                    </div>
                    <label className="uk-form-label uk-text-muted uk-text-0_8">배송 정보</label>
                    <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                        {
                            order && order.delivery && (
                                <div>
                                    <p className="uk-margin-remove">{order.delivery.name} / {phoneNumberDash(order.delivery.phone)}</p>
                                    <p className="uk-margin-remove">({order.delivery.postcode}) {order.delivery.address} {order.delivery.addressDetail}</p>
                                </div>
                            )
                        }
                    </div>
                    <label className="uk-form-label uk-text-muted uk-text-0_8">배송 메모</label>
                    <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                        {
                            order && order.delivery && (
                                <p className="uk-margin-remove">{order.delivery.message ? order.delivery.message : "없음"}</p>
                            )
                        }

                    </div>
                    {
                        order && order.payType !== 2 && order.payType !== 3 ? (
                            order.payLog && order.payLog.result && (
                                <Fragment>
                                    <label className="uk-form-label uk-text-muted uk-text-0_8">결제 방법</label>
                                    <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                                        <p className="uk-margin-remove">
                                            {order.payLog.result.payMethodName}
                                        </p>
                                    </div>
                                    {
                                        order.payLog.result.payMethod === "VBANK" ? (
                                            <Fragment>
                                                <label className="uk-form-label uk-text-muted uk-text-0_8">계좌 정보</label>
                                                <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                                                    <p className="uk-margin-remove">{order.payLog.result.vbankName}</p>
                                                    <p className="uk-margin-remove">{order.payLog.result.vbankNum}</p>
                                                    <p className="uk-margin-remove">예금주 : 주식회사 이니스컴퍼니</p>
                                                </div>
                                                <label className="uk-form-label uk-text-muted uk-text-0_8">입금 금액</label>
                                                <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                                                    <p className="uk-margin-remove">{(order.price + order.deliveryPrice).toLocaleString()}원</p>
                                                </div>

                                                <label className="uk-form-label uk-text-muted uk-text-0_8">입금 기간</label>
                                                <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                                                    <p className="uk-margin-remove">
                                                        {`${order.payLog.result.vbankExpDate.substring(0,4)}년 ${order.payLog.result.vbankExpDate.substring(4, 6)}월 ${order.payLog.result.vbankExpDate.substring(6, 8)}일 까지`}
                                                    </p>
                                                </div>
                                            </Fragment>
                                        ) : (
                                            <Fragment>
                                                <label className="uk-form-label uk-text-muted uk-text-0_8">결제 금액</label>
                                                <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                                                    <p className="uk-margin-remove">{(parseInt(order.payLog.result.paidMoney, 10)).toLocaleString()}원</p>
                                                </div>
                                            </Fragment>
                                        )
                                    }
                                    <label className="uk-form-label uk-text-muted uk-text-0_8">송장 번호</label>
                                    <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                                        <p className="uk-margin-remove">
                                            <span className="uk-pointer" onClick={() => window.open(order.parcelCompany==='cj' ? `https://trace.cjlogistics.com/next/tracking.html?wblNo=${order.invoice}` : `http://www.hanjinexpress.hanjin.net/customer/hddcw18.tracking?w_num=${order.invoice}`, "_blank", "width = 750, height = 500, top = 100, left = 200")}>
                                                {order.invoice ? order.invoice : "송장번호가 등록되지 않은 주문건입니다."}
                                            </span>

                                        </p>
                                    </div>
                                </Fragment>
                            )
                        ) : (
                            <Fragment>
                                <label className="uk-form-label uk-text-muted uk-text-0_8">결제 방법</label>
                                <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                                    <p className="uk-margin-remove">
                                        {order && order.payType === 2 && `네이버 페이(네이버 주문번호: ${order.oId && order.oId.split('_').length > 1 ? order.oId.split('_')[1] : order.oId.split('_')[0]})`}
                                    </p>
                                </div>
                            </Fragment>
                        )
                    }
                    <label className="uk-form-label uk-text-muted uk-text-0_8">주문날짜</label>
                    <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                        <p className={`uk-margin-remove uk-margin-remove`}>{order && order.regdate}</p>
                    </div>
                    <label className="uk-form-label uk-text-muted uk-text-0_8">주문상태</label>
                    <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                        <p className={`uk-margin-remove ${order && order.status === 6 ? "uk-text-danger" : "uk-text-primary"} uk-margin-remove`}>{order && order.statusName}</p>
                    </div>
                    <label className="uk-form-label uk-text-muted uk-text-0_8">송장 번호</label>
                    <div className="uk-margin-xsmall-top uk-margin-bottom uk-text-0_95">
                        <p className="uk-margin-remove">
                            <span className="uk-pointer" onClick={() => window.open(order.parcelCompany==='cj' ? `https://trace.cjlogistics.com/next/tracking.html?wblNo=${order.invoice}` : `http://www.hanjinexpress.hanjin.net/customer/hddcw18.tracking?w_num=${order.invoice}`, "_blank", "width = 750, height = 500, top = 100, left = 200")}>
                                {order && order.invoice ? order.invoice : "송장번호가 등록되지 않은 주문건입니다."}
                            </span>

                        </p>
                    </div>
                    <label className="uk-form-label uk-text-muted uk-text-0_8">관리자 주문 메모</label>
                    <div>
                        {
                            order && order.memo ? (
                                <MemoFormTextAreaStyle orderSeq={order && order.seq} orderMemo={order.memo} isUserModal={2} />
                            ) : (
                                <Fragment>
                                    <button
                                        className="uk-button uk-button-default uk-button-small"
                                        type="button"
                                    >
                                        관리자 메모
                                    </button>
                                    <div data-uk-drop="mode: click">
                                        <div className="uk-card uk-card-body uk-card-default">
                                            <MemoForm orderSeq={order && order.seq} isUserModal={2} />
                                        </div>
                                    </div>
                                </Fragment>
                            )
                        }
                    </div>
                </div>
                <div className="uk-width-1-1 uk-width-1-2@m">
                    {
                        order && order.detail.map((orderDetail, index) => (
                            <div className="uk-width-1-1 uk-margin-remove-top" key={index}>
                                <div className="uk-text-0_9 uk-grid-collapse uk-flex-middle" data-uk-grid>
                                    <div className="uk-width-auto">
                                        <img alt="" className="uk-margin-remove-vertical uk-object-cover uk-width-50 uk-height-50" src={orderDetail.product.img[0]} />
                                    </div>
                                    <div className="uk-width-expand uk-position-relative uk-margin-left">
                                        <p className="uk-margin-remove-bottom">{orderDetail.product_name}</p>
                                        <p className="uk-margin-remove-bottom uk-margin-remove-top uk-text-0_8">{orderDetail && `- ${orderDetail.option_name}`} / {orderDetail.qty}개</p>
                                        <div className="uk-margin-remove-bottom uk-margin-xsmall-top uk-clearfix">
                                            <div className="uk-float-right">{Number(orderDetail.price * orderDetail.qty).toLocaleString()}원</div>
                                        </div>
                                    </div>
                                </div>
                                <div className="uk-margin-small-top uk-margin-small-bottom uk-text-right">
                                    {
                                        orderDetail.status === 5 ||  orderDetail.status === 2 ? (
                                            <span className="uk-text-danger uk-text-0_9">{orderDetail.statusName}된 상품입니다.</span>
                                        ) : (
                                            <span className="uk-text-danger uk-text-0_9">{orderDetail.statusName}</span>
                                        )
                                    }
                                    {
                                        order.detail.length-1 !== index &&
                                        <hr />
                                    }
                                </div>
                            </div>
                        ))
                    }
                    <hr/>
                    <div>
                        <div className="uk-clearfix">
                            <div className="uk-float-right uk-width-1-1 uk-text-right">
                                <div className="uk-flex-middle" data-uk-grid>
                                    <div className="uk-text-0_9 uk-width-160">총 상품가격</div>
                                    <div className="uk-width-expand">{getProductAllMoney(order).toLocaleString() }원</div>
                                </div>
                                <div className="uk-flex-middle uk-margin-small-top" data-uk-grid>
                                    <div className="uk-text-0_9 uk-width-160">배송비</div>
                                    <div className="uk-width-expand">{getDeliveryMoney(order).toLocaleString()}원</div>
                                </div>
                                {
                                    getAddDeliveryPrice(order) > 0 && (
                                        <div className="uk-flex-middle uk-margin-small-top" data-uk-grid>
                                            <div className="uk-text-0_9 uk-width-160">도서산간배송 추가비용</div>
                                            <div className="uk-width-expand">{getAddDeliveryPrice(order).toLocaleString()}원</div>
                                        </div>
                                    )
                                }
                                {
                                    getCancelPrice(order) > 0 && (
                                        <div className="uk-flex-middle uk-margin-small-top" data-uk-grid>
                                            <div className="uk-text-0_9 uk-width-160">취소/환불 금액</div>
                                            <div className="uk-width-expand">{getCancelPrice(order).toLocaleString()}원</div>
                                        </div>
                                    )
                                }
                            </div>
                        </div>
                        <hr />
                        <div className="uk-clearfix uk-margin-small-bottom">
                            <div className="uk-float-right uk-width-1-1@m uk-text-right">
                                <div data-uk-grid>
                                    <div className="uk-width-1-2">최종 결제금액</div>
                                    <div className="uk-width-1-2 uk-text-primary uk-text-bold">{getTotalMoney(order).toLocaleString()}원</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default OrderDetailStyle;
