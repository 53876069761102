import React, { useEffect, useCallback, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Link, useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import { destroyAdminAction } from '../../../reducers/admin';
import {loadOrdersAction, updateOrderDetailAction, updateOrderDetailResetAction} from '../../../reducers/order';
import { loadCountsForSidenavAction } from '../../../reducers/analytics';
import { loadUserAction } from '../../../reducers/user';
import { loadGetOrderDetailCountByStatusAction } from "../../../actions/order/action";

import { useFormData } from '../../../hook';
import UseTwoDepthCheckboxForm from '../../../hook/UseTwoDepthCheckboxForm';

import { phoneFormat } from '../../../utils';

import UIKit from "../../../Components/UIKit";
import OrderHeaderStyle from '../Order/OrderHeaderStyle';
import Pagination from "../Pagination";
import Loader  from '../Loader';
import HeaderDetailOptions from '../HeaderDetailOptions';
import OrderTabStyle from './TabStyle';
import MemoForm from './MemoForm';
import MemoFormTextAreaStyle from './MemoFormTextAreaStyle';
import UserModal from '../User/UserModal';
import OrderModal from './OrderModal';
import ExchangeModal from './ExchangeModal';

const ExchangeTableStyle = ({ payType }) => {
    const dispatch = useDispatch();
    const {
        orders,
        loadOrdersLoading,
        loadOrdersDone,
        loadOrdersError,
        updateOrderDetailDone,
        updateOrderDetailError,
        updateOrderDetail,
        loadGetOrderDetailCountByStatus,
    } = useSelector((state) => state.order);

    const { selectedBrand, loadUserDone } = useSelector((state) => state.user);

    const [openUserModal, setOpenUserModal] = useState(false);
    const [targetUserSeq, setTargetUserSeq] = useState("");
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const [targetOrderSeq, setTargetOrderSeq] = useState("");
    const [openExchangeModal, setOpenExchangeModal] = useState(false);
    const [targetOrderDetail, setTargetOrderDetail] = useState(null);
    const [nowTab, setNowTab] = useState(4);

    const twoDepthCheckboxForm = UseTwoDepthCheckboxForm({});
    const history = useHistory();
    const location = useLocation();

    const locationSearch = location.search;
    const parsedLocationSearch = queryString.parse(locationSearch);

    let defaultColumn = '';
    if(parsedLocationSearch.sort) {
        defaultColumn = JSON.parse(atob(parsedLocationSearch.sort));
        defaultColumn = Object.keys(defaultColumn)[0];
    }

    const sortForm = useFormData({
        column: defaultColumn ? defaultColumn :'regdate',
        sort: 'desc'
    });

    useEffect(() => {
        let query = location.search;
        const parsedQuery = queryString.parse(query);
        let status = 4;
        if(parsedQuery.status) {
            status = parsedQuery.status;
            if(status !== 'all') {
                status = parseInt(status, 10);
            }
            setNowTab(status);
        }
        dispatch(loadOrdersAction(`/details${query}`));
        dispatch(loadGetOrderDetailCountByStatusAction(2));
    }, [location, selectedBrand]);

    useEffect(() => {
        if(loadOrdersError) {
            alert(loadOrdersError.msg);
        }
    }, [loadOrdersError]);

    useEffect(() => {
        if(loadOrdersDone) {
            twoDepthCheckboxForm.init(orders);
        }
    }, [loadOrdersDone]);

    const onClickColumn = useCallback((clickedColumn) => {
        let sort = {};
        let sortQuery = '';
        if (sortForm.value.column !== clickedColumn) {
            sortForm.onChange({column : clickedColumn, sort: 'desc'});
            sort[clickedColumn] = 'desc';
            sort = JSON.stringify(sort);
            sortQuery = `sort=${btoa(sort)}`;
        } else {
            sortForm.onChange({
                sort: sortForm.value.sort === 'desc' ? 'asc' : 'desc'
            });
            sort[clickedColumn] = sortForm.value.sort === 'desc' ? 'asc' : 'desc';
            sort = JSON.stringify(sort);
            sortQuery = `sort=${btoa(sort)}`;
        }

        let nextQueryString = sortQuery;
        if(location.search) {
            let nowQueryStringObj = queryString.parse(location.search);
            delete nowQueryStringObj.sort;
            if(decodeURIComponent(queryString.stringify(nowQueryStringObj))) {
                nextQueryString = `${decodeURIComponent(queryString.stringify(nowQueryStringObj))}&${sortQuery}`;
            }
        }

        history.push({
            pathname: location.pathname,
            search: nextQueryString
        });
    }, [history, location, sortForm]);


    const onSubmitOrderDetailStatus = useCallback((orderStatus) => {
        const copyTwoDepthCheckboxFormValue = JSON.parse(JSON.stringify(twoDepthCheckboxForm.value)); //깊은복사
        delete copyTwoDepthCheckboxFormValue.total;
        delete copyTwoDepthCheckboxFormValue.isShowDetailOption;


        // 서버에 호출하기전 체크박스 체크안된 주문,주문상세 전부 제거
        for(let copyParentCheckboxKey of Object.keys(copyTwoDepthCheckboxFormValue)) {
            if(copyTwoDepthCheckboxFormValue[copyParentCheckboxKey].checked === false) {
                delete copyTwoDepthCheckboxFormValue[copyParentCheckboxKey];
                continue;
            }

            for(let copyChildCheckboxKey of Object.keys(copyTwoDepthCheckboxFormValue[copyParentCheckboxKey].detailCheckbox)) {
                if(copyTwoDepthCheckboxFormValue[copyParentCheckboxKey].detailCheckbox[copyChildCheckboxKey].checked === false) {
                    delete copyTwoDepthCheckboxFormValue[copyParentCheckboxKey].detailCheckbox[copyChildCheckboxKey];
                }
            }
        }
        let endPoint = "";
        if(payType !== 2 && payType !== 3) {
            dispatch(updateOrderDetailAction('admin/orders/details', {data: JSON.stringify(copyTwoDepthCheckboxFormValue), status: orderStatus}));
        } else if(payType === 2) {
            dispatch(updateOrderDetailAction('admin/orders/details/cancelApplyCancel', {data: JSON.stringify(copyTwoDepthCheckboxFormValue), status: orderStatus}));
        }
    }, [twoDepthCheckboxForm, payType]);

    useEffect(() => {
        if(updateOrderDetailDone) {
            let query = location.search;
            dispatch(loadOrdersAction(`details/${query}`));
            dispatch(loadCountsForSidenavAction());
            alert(updateOrderDetail.msg);
            dispatch(updateOrderDetailResetAction());
        }

        if(updateOrderDetailError) {
            alert(updateOrderDetailError.msg);
            dispatch(updateOrderDetailResetAction());
        }
    }, [updateOrderDetailDone, updateOrderDetailError]);

    const getPayIcon = useCallback((payType) => {
      if(payType === 2) {
          return (
              <img src="/image/naver_simple.png" className="uk-object-cover uk-width-15 uk-height-auto uk-margin-xsmall-right uk-text-top uk-margin-4-top" />
          );
      } else if(payType === 3) {
          return (
              <img src="/image/imweb.png" className="uk-object-cover uk-width-15 uk-height-auto uk-margin-xsmall-right uk-text-top uk-margin-4-top" />
          );
      }
      return null;

  }, []);

  const getPayType = (order) => {
    if(order.payType === 2) {
        return "네이버 페이";
    } else if(order.payType === 3) {
        return "아임웹"
    }else{
        if(order.payLog && order.payLog.result) {
            return order.payLog.result.payMethodName;
        } else {
            return "알수없음";
        }
    }
}

    const getCanceledPrice = useCallback((order) => {
        let canceledPrice = 0;
        for(let payCancelLog of order.payCancelLog) {
            canceledPrice = canceledPrice + parseInt(payCancelLog.cancelPrice, 10);
        }

        return canceledPrice;
    }, []);

    const getTotalPrice = useCallback((order) => {
        let totalPrice = 0;

        const canceledPrice = getCanceledPrice(order);
        if(order.price - canceledPrice < order.brand.freeShippingStandard) {
            totalPrice = (order.price + order.deliveryPrice + order.addDeliveryPrice - getCanceledPrice(order)).toLocaleString();
        } else {
            totalPrice = (order.price - getCanceledPrice(order)).toLocaleString();
        }

        return totalPrice;
    }, []);

    const onClickOpenUserModal = useCallback((userSeq) => {
        setTargetUserSeq(userSeq);
        setOpenUserModal(true);
    }, []);

    const onClickOpenOrderModal = useCallback((orderSeq) => {
        setTargetOrderSeq(orderSeq);
        setOpenOrderModal(true);
    }, []);

    const onClickOpenExchangeModal = useCallback((detail) => {
        setTargetOrderDetail(detail);
        setOpenExchangeModal(true);
    }, []);

    const onClickCopyClipBoard = useCallback((e) => {
        const text = window.document.createElement('textarea');
        window.document.body.appendChild(text);
        text.value = e.target.innerText;
        text.focus();
        text.select();
        text.setSelectionRange(0, 99999);
        const isCopy = document.execCommand('copy');
        if(isCopy) {
            alert('복사되었습니다.');
        } else {
            alert('복사실패했습니다. 관리자에게 문의하여 주세요.');
        }
        document.body.removeChild(text);
    }, []);

    const onClickTab = (status) => {
        let query=`page=1&status=${status}`;
        setNowTab(status);
        history.push({
            pathname: location.pathname,
            search: query
        });
    }

    return loadOrdersLoading ? (
        <div>
            <Loader />
        </div>
    ) : loadOrdersDone && (
        <div>
            <UIKit.Div classOptions={"uk-width-1-1"}>
                <OrderHeaderStyle />
            </UIKit.Div>
            <div>
                <ul data-uk-tab>
                    <li className={nowTab === 4 ? "uk-active" : ""} onClick={() => onClickTab(4)}>
                        <a>
                            <span className="uk-margin-small-right">교환요청</span>
                            <span className="uk-badge">{loadGetOrderDetailCountByStatus && loadGetOrderDetailCountByStatus.requestExchangeCount ? loadGetOrderDetailCountByStatus.requestExchangeCount.toLocaleString() : 0}</span>
                        </a>
                    </li>
                    <li className={nowTab === 11 ? "uk-active" : ""} onClick={() => onClickTab(11)}>
                        <a>
                            <span className="uk-margin-small-right">수거완료</span>
                            <span className="uk-badge">{loadGetOrderDetailCountByStatus && loadGetOrderDetailCountByStatus.collectionCompletedExchangeCount ? loadGetOrderDetailCountByStatus.collectionCompletedExchangeCount.toLocaleString() : 0}</span>
                        </a>
                    </li>
                    <li className={nowTab === 9 ? "uk-active" : ""} onClick={() => onClickTab(9)}>
                        <a>
                            <span className="uk-margin-small-right">재발송중</span>
                            <span className="uk-badge">{loadGetOrderDetailCountByStatus && loadGetOrderDetailCountByStatus.redeliveryExchangeCount ? loadGetOrderDetailCountByStatus.redeliveryExchangeCount.toLocaleString() : 0}</span>
                        </a>
                    </li>
                    <li className={nowTab === 7 ? "uk-active" : ""} onClick={() => onClickTab(7)}>
                        <a>
                            <span className="uk-margin-small-right">교환완료</span>
                            <span className="uk-badge">{loadGetOrderDetailCountByStatus && loadGetOrderDetailCountByStatus.completedExchangeCount ? loadGetOrderDetailCountByStatus.completedExchangeCount.toLocaleString() : 0}</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div>
                <div className="uk-card uk-card-default uk-card-body uk-card-small uk-padding-remove">
                    <div className="uk-overflow-auto uk-margin-top uk-panel-scrollable@m uk-border-none uk-padding-remove uk-resize-none" data-uk-height-viewport="offset-top: true; offset-bottom:120px;">
                        <table className="uk-table uk-table-divider uk-table-small uk-table-border uk-overflow-auto">
                            <thead className="uk-text-0_8 uk-text-center">
                                <tr>
                                    <th className="uk-text-left uk-width-215">
                                        <input
                                            type="checkbox"
                                            className="uk-checkbox uk-margin-small-right"
                                            onChange={twoDepthCheckboxForm.onChangeCheckboxAll}
                                            checked={twoDepthCheckboxForm.value.total && twoDepthCheckboxForm.value.total.checked ? twoDepthCheckboxForm.value.total.checked : false}
                                        />
                                        <span>주문번호</span>
                                    </th>
                                    <th className="uk-text-nowrap uk-table-expand">주문상품</th>
                                    <th className="uk-table-shrink uk-text-nowrap">상품금액</th>
                                    <th className="uk-table-shrink uk-text-nowrap">수량</th>
                                    <th className="uk-table-shrink uk-text-nowrap">상태</th>
                                    <th className="uk-table-shrink uk-text-nowrap">배송</th>
                                    <th className="uk-width-auto uk-min-width-200">배송정보</th>
                                    <th className="uk-width-auto uk-min-width-200">교환정보</th>
                                    <th className="uk-table-shrink uk-text-nowrap">결제내역</th>
                                </tr>
                            </thead>
                            <tbody className="uk-text-0_8">
                                {
                                    orders && Object.keys(orders).length > 0 ? (
                                        orders.data.map((order,index) => ((
                                                order.detail.map((detail, detailIndex) => (
                                                    <tr key={detail.seq} className={index%2==0 ? "uk-background-gray5" : ""}>
                                                        {
                                                            detailIndex > 0 === false && (
                                                                <Fragment>
                                                                    <td rowSpan={order.detail.length} className="uk-width-215 uk-text-nowrap">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="uk-checkbox uk-text-top uk-margin-4-top uk-margin-small-right"
                                                                            onChange={() => twoDepthCheckboxForm.first(order.seq)}
                                                                            checked={twoDepthCheckboxForm.value[order.seq] && twoDepthCheckboxForm.value[order.seq].checked ? twoDepthCheckboxForm.value[order.seq].checked : false}
                                                                        />
                                                                        <div className="uk-display-inline-block">
                                                                            <p className="uk-margin-remove uk-text-bold uk-pointer uk-text-0_95" onClick={onClickCopyClipBoard}>{order.seq}</p>
                                                                            <p className="uk-margin-remove"><span className="uk-display-inline-block uk-text-0_8 uk-vertical-top">{order.regdate.substring(0, 16)}</span><span className="uk-display-inline-block uk-text-0_9 uk-margin-xsmall-left uk-vertical-top">({order.brand.name})</span></p>
                                                                            {
                                                                                order.buyer &&order.buyer.name && (
                                                                                    <p className="uk-margin-small-top uk-margin-remove-bottom">
                                                                                        {
                                                                                            getPayIcon(order.payType)
                                                                                        }
                                                                                        <span
                                                                                            className="uk-pointer"
                                                                                            onClick={() => order.user_seq === 0 ? onClickOpenOrderModal(order.seq) : onClickOpenUserModal(order.user_seq)}
                                                                                        >
                                                                                            {order.buyer.name}
                                                                                            {order.payType !== 2 && order.payType !== 3 && order.user_seq === 0 && "(비회원)"}
                                                                                        </span>
                                                                                    </p>
                                                                                )
                                                                            }
                                                                            <p className="uk-margin-small-top uk-margin-remove-bottom uk-text-success">{order.statusName}</p>
                                                                        </div>
                                                                    </td>
                                                                    <OrderModal openOrderModal={openOrderModal} setOpenOrderModal={setOpenOrderModal} orderSeq={order.seq} />
                                                                </Fragment>
                                                            )
                                                        }
                                                        <td rowSpan={1} className="uk-text-nowrap">
                                                          <div className="uk-overflow-auto">
                                                              <div className="uk-display-inline-block uk-vertical-top">
                                                                <input
                                                                    type="checkbox"
                                                                    className="uk-checkbox"
                                                                    onChange={() => twoDepthCheckboxForm.second(order.seq, detail.seq)}
                                                                    checked={
                                                                        twoDepthCheckboxForm.value[order.seq] &&
                                                                        twoDepthCheckboxForm.value[order.seq].detailCheckbox[detail.seq].checked ?
                                                                        twoDepthCheckboxForm.value[order.seq].detailCheckbox[detail.seq].checked :
                                                                        false
                                                                    }
                                                                />
                                                                <div className="uk-display-inline-block uk-margin-3-left">
                                                                  <a href={order.brand.domain+"/product/all/"+detail.product?.seq} target="_blank">
                                                                      <img
                                                                          className="uk-preserve-width uk-border-circle"
                                                                          src={detail.product.img && detail.product.img[0]}
                                                                          width={"40"}
                                                                          alt=""
                                                                      />
                                                                  </a>
                                                                </div>
                                                              </div>
                                                              <div className="uk-display-inline-block uk-vertical-top uk-margin-xsmall-left">
                                                                  <span className={detail.product?.name === undefined ? 'uk-text-danger' : ""}>{detail.product?.name === undefined ? "삭제된 상품" : detail.product.name}</span>
                                                                  <br/>
                                                                  <span>{detail.option_name}</span>
                                                              </div>
                                                            </div>
                                                            <div className="uk-margin-small-top">
                                                                <button
                                                                    className="uk-button uk-button-default uk-button-small"
                                                                    type="button"
                                                                    onClick={() => onClickOpenExchangeModal(detail)}
                                                                >
                                                                    재발송처리
                                                                </button>
                                                            </div>
                                                        </td>
                                                        <td rowSpan={1}>
                                                          <div className="uk-overflow-auto uk-text-center uk-text-nowrap">
                                                              {(detail.price).toLocaleString()}원
                                                          </div>
                                                        </td>
                                                        <td rowSpan={1}>
                                                          <div className="uk-overflow-auto uk-text-center uk-text-nowrap">
                                                              {detail.qty}개
                                                          </div>
                                                        </td>
                                                        <td rowSpan={1}>
                                                          <div className="uk-overflow-auto uk-text-center uk-text-nowrap">
                                                            {detail.statusName}
                                                          </div>
                                                        </td>
                                                        {
                                                            detailIndex > 0 === false && (
                                                                <Fragment>
                                                                    <td rowSpan={order.detail.length}>
                                                                      <div className="uk-overflow-auto uk-text-center uk-text-nowrap">
                                                                        <span>{order.parcelCompanyName}</span>
                                                                        <br/>
                                                                        {
                                                                              order.invoice && (
                                                                                <span 
                                                                                className="uk-pointer"
                                                                                onClick={() => window.open(order.parcelCompany==='cj' ? `https://trace.cjlogistics.com/next/tracking.html?wblNo=${order.invoice}` : `http://www.hanjinexpress.hanjin.net/customer/hddcw18.tracking?w_num=${order.invoice}`, "_blank", "width = 750, height = 500, top = 100, left = 200")}
                                                                              >
                                                                                {order.invoice}
                                                                              </span>
                                                                              )
                                                                          }
                                                                      </div>
                                                                    </td>
                                                                    <td rowSpan={order.detail.length} className="uk-text-0_9">
                                                                      <div className="uk-overflow-auto">
                                                                        {
                                                                            order.delivery && (
                                                                                <div>
                                                                                    <span>{order.delivery.name}</span> / <span>{phoneFormat(order.delivery.phone)}</span>
                                                                                    <div className="uk-margin-3-top">
                                                                                        <span>{order.delivery.address}</span>
                                                                                        <br/>
                                                                                        <span>{order.delivery.addressDetail}</span>
                                                                                        <br/>
                                                                                        <span>우) {order.delivery.postcode}</span>
                                                                                    </div>
                                                                                    <div>
                                                                                        {
                                                                                            order.delivery.message && (
                                                                                              <div className="uk-margin-3-top">
                                                                                                    <span>[배송메시지]</span>
                                                                                                    <br />
                                                                                                    <span>{order.delivery.message}</span>
                                                                                                    <br />
                                                                                              </div>
                                                                                            )
                                                                                        }

                                                                                        {
                                                                                            (detail.status === 5 || detail.status === 2) && (
                                                                                              <div className="uk-margin-3-top">
                                                                                                    <span>[취소 사유] : </span>
                                                                                                    <br />
                                                                                                    <span>{detail.cancelReason}</span>
                                                                                              </div>
                                                                                            )
                                                                                        }

                                                                                    </div>
                                                                                    <div className="uk-margin-small-top">
                                                                                        {
                                                                                            order.memo ? (
                                                                                                <MemoFormTextAreaStyle orderSeq={order.seq} orderMemo={order.memo} />
                                                                                            ) : (
                                                                                                <Fragment>
                                                                                                    <button
                                                                                                        className="uk-button uk-button-default uk-button-small"
                                                                                                        type="button"
                                                                                                    >
                                                                                                        관리자 메모
                                                                                                    </button>
                                                                                                    <div data-uk-drop="mode: click">
                                                                                                        <div className="uk-card uk-card-body uk-card-default">
                                                                                                            <MemoForm orderSeq={order.seq} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Fragment>
                                                                                            )
                                                                                        }

                                                                                    </div>

                                                                                </div>
                                                                            )
                                                                        }
                                                                        </div>
                                                                    </td>
                                                                </Fragment>

                                                            )
                                                        }
                                                        {
                                                            detail.exchangeInfo ? (
                                                                <Fragment>
                                                                    <td className="uk-text-0_9">
                                                                      <div className="uk-overflow-auto">
                                                                            <span>클레임요청일 : {detail.exchangeInfo.claimRequestDate && detail.exchangeInfo.claimRequestDate.substring(0, 16)}</span>
                                                                            <br/>
                                                                            <span>클레임처리 상태 : {detail.exchangeInfo.claimStatus && detail.exchangeInfo.claimStatus}</span>
                                                                            <br/>
                                                                            <span>교환 사유 : {`${detail.exchangeInfo.exchangeReason && detail.exchangeInfo.exchangeReason}  ${detail.exchangeInfo.exchangeDetailedReason && `: ${detail.exchangeInfo.exchangeDetailedReason}`}`}</span>
                                                                            <br/>
                                                                            <span>수거 방법 : {detail.exchangeInfo.collectDeliveryMethod && detail.exchangeInfo.collectDeliveryMethod=="RETURN_INDIVIDUAL" ? "직접발송" : "자동수거"}</span>
                                                                            <br/>
                                                                            <span>수거 상태 : {detail.exchangeInfo.collectStatus && detail.exchangeInfo.collectStatus}</span>
                                                                            <br/>
                                                                            <span>수거 택배사 : {detail.exchangeInfo.collectDeliveryCompany && detail.exchangeInfo.collectDeliveryCompany}</span>
                                                                            <br/>
                                                                            {
                                                                              detail.exchangeInfo.collectTrackingNumber && (
                                                                                <>
                                                                                  <span className="uk-pointer" onClick={() => window.open(`https://trace.cjlogistics.com/next/tracking.html?wblNo=${detail.exchangeInfo.collectTrackingNumber}`, "_blank", "width = 750, height = 500, top = 100, left = 200")}>
                                                                                    수거 송장 번호 : {detail.exchangeInfo.collectTrackingNumber}
                                                                                  </span>
                                                                                  <br/>
                                                                                </>
                                                                              )
                                                                            }
                                                                            <span>
                                                                              수거 배송비 : {((detail.exchangeInfo.claimDeliveryFeeDemandAmount ? detail.exchangeInfo.claimDeliveryFeeDemandAmount : 0) - (detail.exchangeInfo.claimDeliveryFeeDiscountAmount ? detail.exchangeInfo.claimDeliveryFeeDiscountAmount : 0)).toLocaleString()}원
                                                                              {detail.exchangeInfo.claimDeliveryFeePayMeans && detail.exchangeInfo.claimDeliveryFeePayMethod && ("(" + detail.exchangeInfo.claimDeliveryFeePayMeans + " : " + detail.exchangeInfo.claimDeliveryFeePayMethod + ")")}
                                                                            </span>
                                                                            <hr className="uk-border-gray uk-margin-xsmall-top uk-margin-xsmall-bottom" />
                                                                            {
                                                                                detail.exchangeInfo.collectAddress && (
                                                                                    <Fragment>
                                                                                        <p className="uk-margin-remove">[수거 정보]</p>
                                                                                        <span>{detail.exchangeInfo.collectAddress.name ? detail.exchangeInfo.collectAddress.name : '정보가 존재하지 않습니다.'} / </span>
                                                                                        <span>{detail.exchangeInfo.collectAddress.tel1 ? detail.exchangeInfo.collectAddress.tel1 : '정보가 존재하지 않습니다.'}</span>
                                                                                        <div>
                                                                                            <span>{detail.exchangeInfo.collectAddress.baseAddress ? detail.exchangeInfo.collectAddress.baseAddress : '정보가 존재하지 않습니다.'}</span>
                                                                                            <br/>
                                                                                            <span>{detail.exchangeInfo.collectAddress.detailedAddress ? detail.exchangeInfo.collectAddress.detailedAddress : '정보가 존재하지 않습니다.'}</span>
                                                                                            <br/>
                                                                                            <span>우) {detail.exchangeInfo.collectAddress.zipCode ? detail.exchangeInfo.collectAddress.zipCode : '정보가 존재하지 않습니다.'}</span>
                                                                                        </div>
                                                                                    </Fragment>
                                                                                )
                                                                            }

                                                                        </div>
                                                                    </td>
                                                                </Fragment>
                                                            ) : (
                                                                <td>
                                                                    교환정보가 존재하지 않습니다.
                                                                </td>
                                                            )
                                                        }
                                                        {
                                                            detailIndex > 0 === false ? (
                                                                <Fragment>
                                                                    <td rowSpan={order.detail.length} className="uk-text-nowrap uk-text-0_9">
                                                                        <span>상품 총 금액 : {order.price.toLocaleString()}원</span>
                                                                        <br/>
                                                                        <span>배송비 : {order.deliveryPrice.toLocaleString()}원</span>
                                                                        <br/>
                                                                        <span>도서산간 추가 배송비 : {order.addDeliveryPrice.toLocaleString()}원</span>
                                                                        <br/>
                                                                        <span>취소 금액 : {getCanceledPrice(order).toLocaleString()}원</span>
                                                                        <br/>
                                                                        <span>총 결제 금액 : {getTotalPrice(order).toLocaleString()}원</span>
                                                                        <br/>
                                                                        <span>결제방법 : {getPayType(order)}</span>
                                                                    </td>
                                                                </Fragment>

                                                            ) : null
                                                        }

                                                    </tr>
                                                ))
                                            ))
                                        )
                                    ) : (
                                        <tr>
                                            <td colSpan={14} className="uk-text-center">목록이 존재하지않습니다.</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="uk-margin-top">
                    <Pagination
                        currentPage={orders.current_page ? orders.current_page : 1}
                        pageLast={orders.last_page ? orders.last_page : 1}
                    />
                </div>
                <UserModal openUserModal={openUserModal} setOpenUserModal={setOpenUserModal} userSeq={targetUserSeq} />
                <OrderModal openOrderModal={openOrderModal} setOpenOrderModal={setOpenOrderModal} orderSeq={targetOrderSeq} />
                <ExchangeModal openExchangeModal={openExchangeModal} setOpenExchangeModal={setOpenExchangeModal} targetOrderDetail={targetOrderDetail} />
            </div>


        </div>
    )
};

ExchangeTableStyle.propTypes = {
    payType: PropTypes.number.isRequired,
};

export default ExchangeTableStyle;
