import React, { useCallback, Fragment, useState, useEffect } from 'react';
import {useDispatch, useSelector} from "react-redux";

import { updateOrderDetailAction} from "../../../reducers/order";

import {managerChangeOrderDetailStatusAction, managerChangeOrderForceCancelAction, managerChangeOrderForceCancelResetAction} from "../../../actions/order/action";

import { phoneFormat } from '../../../utils';

import CancelInfoForm from './CancelInfoForm';


const ItemStyle = ({
    order,
    detail,
	orderIndex,
    detailIndex,
    twoDepthCheckboxForm,
    onClickOpenUserModal,
    DeliveryForm,
    MemoForm,
    MemoFormTextAreaStyle,
    onClickOpenOrderModal,
    onClickOpenOrderSheetModal,
    onClickRequestReturnOrderModal,
    nowTab,
}) => {
    const dispatch = useDispatch();

    const {
      updateManagerChangeOrderForceCancelDone,
      updateManagerChangeOrderForceCancelError,
      updateManagerChangeOrderForceCancel
    } = useSelector((state) => state.order);

    const onSubmitCancelApply = useCallback((orderDetailSeq) => {
        const formData = new FormData();
        formData.append('status', 2);
        formData.append("_method", "PUT");
        dispatch(managerChangeOrderDetailStatusAction(orderDetailSeq, formData));

    }, []);

    const onSubmitForceCancel = useCallback((orderSeq) => {
      if(window.confirm("취소완료처리 하시겠습니까?")){
        dispatch(managerChangeOrderForceCancelAction(orderSeq));
      }
   }, []);

   useEffect(() => {
      if(updateManagerChangeOrderForceCancelDone) {
          alert(updateManagerChangeOrderForceCancel.msg);
          window.location.reload();
      }

      if(updateManagerChangeOrderForceCancelError) {
          alert(updateManagerChangeOrderForceCancelError.msg);
          dispatch(managerChangeOrderForceCancelResetAction());
      }
  }, [updateManagerChangeOrderForceCancelDone, updateManagerChangeOrderForceCancelError]);

    const getPayIcon = useCallback((payType) => {
        if(payType === 2) {
            return (
                <img src="/image/naver_simple.png" className="uk-object-cover uk-width-15 uk-height-auto uk-margin-xsmall-right uk-text-top uk-margin-4-top" />
            );
        } else if(payType === 3) {
            return (
                <img src="/image/imweb.png" className="uk-object-cover uk-width-15 uk-height-auto uk-margin-xsmall-right uk-text-top uk-margin-4-top" />
            );
        }
        return null;

    }, []);

    const getPayType = (order) => {
        if(order.payType === 2) {
            return "네이버 페이";
        } else if(order.payType === 3) {
            return "아임웹"
        }else{
            if(order.payLog && order.payLog.result) {
                return order.payLog.result.payMethodName;
            } else {
                return "알수없음";
            }
        }
    }

    const onClickCopyClipBoard = useCallback((e) => {
        const text = window.document.createElement('textarea');
        window.document.body.appendChild(text);
        text.value = e.target.innerText;
        text.focus();
        text.select();
        text.setSelectionRange(0, 99999);
        const isCopy = document.execCommand('copy');
        if(isCopy) {
            alert('복사되었습니다.');
        } else {
            alert('복사실패했습니다. 관리자에게 문의하여 주세요.');
        }
        document.body.removeChild(text);
    }, []);

    return (
        <tr key={detail.seq} className={orderIndex%2==0 ? "uk-background-gray5" : ""}>
            {
                detailIndex > 0 === false && (
                    <Fragment>
                        <td rowSpan={order.detail.length} className="uk-width-215 uk-text-nowrap">
                            <div className="uk-overflow-auto">
                                <input
                                    type="checkbox"
                                    className="uk-checkbox uk-text-top uk-margin-4-top uk-margin-small-right"
                                    onChange={() => twoDepthCheckboxForm.first(order.seq)}
                                    checked={
                                        twoDepthCheckboxForm.value[order.seq] &&
                                        twoDepthCheckboxForm.value[order.seq].checked ?
                                        twoDepthCheckboxForm.value[order.seq].checked :
                                        false
                                    }
                                />
                                <div className="uk-display-inline-block">
                                    <p
                                        className="uk-margin-remove uk-text-bold uk-pointer uk-text-0_95"
                                        onClick={onClickCopyClipBoard}
                                    >
                                        {order.seq}
                                    </p>
                                    <p className="uk-margin-remove"><span className="uk-display-inline-block uk-text-0_8 uk-vertical-top">{order.regdate.substring(0, 16)}</span><span className="uk-display-inline-block uk-text-0_9 uk-margin-xsmall-left uk-vertical-top">({order.brand.name})</span></p>
                                    {
                                        order.buyer &&order.buyer.name && (
                                            <p className="uk-margin-small-top uk-margin-remove-bottom">
                                                {
                                                    getPayIcon(order.payType)
                                                }
                                                <span
                                                    className={"uk-pointer"}
                                                    onClick={() => order.user_seq === 0 ? onClickOpenOrderModal(order.seq) : onClickOpenUserModal(order.user_seq)}
                                                >
                                                    {order.buyer.name}
                                                    {order.payType !== 2 && order.payType !== 3 && order.user_seq === 0 && "(비회원)"}
                                                </span>
                                            </p>

                                        )
                                    }
                                    <p className="uk-margin-small-top uk-margin-remove-bottom uk-text-success">{order.statusName}</p>
                                    {
                                        order.payType != 3 && (order.status === 4 || order.status === 5) ? (
                                            <div className="uk-margin-xsmall-top">
                                                <button className="uk-button uk-button-default uk-button-small" onClick={() => onClickRequestReturnOrderModal(order)}>반품요청</button>
                                            </div>
                                        ) : null
                                    }
                                    {
                                        (order.payType == 1 || order.payType == 4) && (order.status === 4) ? (
                                            <div className="uk-margin-xsmall-top">
                                                <button className="uk-button uk-button-default uk-button-small" onClick={() => onSubmitForceCancel(order.seq)}>강제취소</button>
                                            </div>
                                        ) : null
                                    }

                                    <div className="uk-margin-xsmall-top">
                                        <button className="uk-button uk-button-default uk-button-small" onClick={() => onClickOpenOrderSheetModal(order.seq)}>주문서 출력</button>
                                    </div>
                                </div>
                            </div>

                        </td>
                    </Fragment>
                )
            }
            <td rowSpan={1} className="uk-text-nowrap">
                <div className="uk-overflow-auto">
                    <div className="uk-display-inline-block uk-vertical-top">
                        <input
                            type="checkbox"
                            className="uk-checkbox uk-margin-remove-top uk-vertical-middle"
                            onChange={() => twoDepthCheckboxForm.second(order.seq, detail.seq)}
                            checked={
                                twoDepthCheckboxForm.value[order.seq] &&
                                twoDepthCheckboxForm.value[order.seq].detailCheckbox[detail.seq] &&
                                twoDepthCheckboxForm.value[order.seq].detailCheckbox[detail.seq].checked ?
                                twoDepthCheckboxForm.value[order.seq].detailCheckbox[detail.seq].checked :
                                false
                            }
                        />
                        <div className="uk-display-inline-block uk-margin-3-left">
                          <a href={order.brand.domain+"/product/all/"+detail.product?.seq} target="_blank">
                              <img
                                  className="uk-preserve-width uk-border-circle"
                                  src={detail.product?.img[0]}
                                  width={"40"}
                                  alt=""
                              />
                          </a>
                      </div>
                    </div>
                    <div className="uk-display-inline-block uk-vertical-top uk-margin-xsmall-left">
                      <span className={detail.product?.name === undefined ? "uk-text-danger" : ""}>{detail.product?.name === undefined ? "삭제된 상품" : detail.product.name}</span>
                      <br/>
                      <span>{detail.option_name}</span>
                      {
                          order.payType !== 3 && detail.status === 1 && (nowTab === "all") && (order.status === 2 || order.status === 3) && (
                              <div className="uk-margin-small-top">
                                  {
                                      order.payLog && order.payLog.result && order.payLog.result.payMethod === 'VBANK' ? (
                                          <Fragment>
                                              <button
                                                  className="uk-button uk-button-default uk-button-small"
                                                  type="button"
                                              >
                                                  취소요청
                                              </button>
                                              <div data-uk-drop="mode: click">
                                                  <div className="uk-card uk-card-body uk-card-default">
                                                      <CancelInfoForm orderDetailSeq={detail.seq} returnInfoParent={order.returnInfo} />
                                                  </div>
                                              </div>
                                          </Fragment>
                                      ) : (
                                          <button
                                              className="uk-button uk-button-default uk-button-small"
                                              type="button"
                                              onClick={() => onSubmitCancelApply(detail.seq)}
                                          >
                                              취소요청
                                          </button>
                                      )
                                  }


                              </div>
                          )
                      }
                    </div>
                </div>
            </td>
            <td rowSpan={1}>
                <div className="uk-overflow-auto uk-text-center uk-text-nowrap">
                    {(detail.price).toLocaleString()}원
                </div>
            </td>
            <td rowSpan={1}>
                <div className="uk-overflow-auto uk-text-center uk-text-nowrap">
                    {detail.qty}개
                </div>
            </td>
            <td rowSpan={1}>
                <div className="uk-overflow-auto uk-text-center uk-text-nowrap">
                    <ul className="uk-list">
                        <li>
                            <p>{detail.statusName}</p>
                        </li>
                        {
                            detail.status === 12 && detail.delayInfo && (
                                <Fragment>
                                    <li>
                                        <p className="uk-margin-remove-bottom uk-text-bold">배송지연 사유</p>
                                        <p className="uk-margin-remove-top">{detail.delayInfo.delayedDispatchReason}</p>

                                    </li>
                                    <li>
                                        <p className="uk-margin-remove-bottom uk-text-bold">배송지연 상세사유</p>
                                        <p className="uk-margin-remove-top">{detail.delayInfo.delayedDispatchDetailedReason}</p>
                                    </li>
                                    <li>
                                        <p className="uk-margin-remove-bottom uk-text-bold">배송예정 날짜</p>
                                        <p className="uk-margin-remove-top">{detail.delayInfo.dispatchDueDate}</p>
                                    </li>
                                </Fragment>
                            )
                        }
                    </ul>
                </div>

            </td>

            {
                detailIndex > 0 === false && (
                    <Fragment>
                        <td rowSpan={order.detail.length}>
                            <div className="uk-overflow-auto uk-text-center uk-text-nowrap">
                                <DeliveryForm 
                                    seq={order.seq}
                                    invoice={order.invoice}
                                    payType={order.payType}
                                    status={order.status}
                                    parcelCompany={order.parcelCompany}
                                    parcelCompanyName={order.parcelCompanyName}
                                />
                                <br/><br/>
                                {
                                    detail.refundInvoice && (
                                        <Fragment>
                                            <span>반품 송장번호 : </span>
                                            <br/>
                                            <span
                                                className="uk-pointer"
                                                onClick={() => window.open(`https://trace.cjlogistics.com/next/tracking.html?wblNo=${detail.refundInvoice}`, "_blank", "width = 750, height = 500, top = 100, left = 200")}
                                            >
                                                {detail.refundInvoice}
                                            </span>
                                        </Fragment>
                                    )
                                }
                            </div>
                        </td>
                        <td rowSpan={order.detail.length} className="uk-text-0_9">
                            <div className="uk-overflow-auto">
                                {
                                    order.delivery && (
                                        <div>
                                            <span>{order.delivery.name}</span> / <span>{phoneFormat(order.delivery.phone)}</span>
                                            <div className="uk-margin-3-top">
                                                <span>{order.delivery.address}</span>
                                                <br/>
                                                <span>{order.delivery.addressDetail}</span>
                                                <br/>
                                                <span>우) {order.delivery.postcode}</span>
                                            </div>
                                            <div>
                                                {
                                                    order.delivery.message && (
                                                        <div className="uk-margin-3-top">
                                                            <span>[배송메시지]</span>
                                                            <br />
                                                            <span>{order.delivery.message}</span>
                                                        </div>
                                                    )
                                                }
                                                {
                                                    (detail.status === 5 || detail.status === 2) && (
                                                        <div className="uk-margin-3-top">
                                                            <span>[취소 사유] </span>
                                                            <br />
                                                            <span>{detail.cancelReason}</span>
                                                        </div>
                                                    )
                                                }

                                            </div>
                                            <div className="uk-margin-small-top">
                                                {
                                                    order.memo ? (
                                                        <MemoFormTextAreaStyle orderSeq={order.seq} orderMemo={order.memo} />
                                                    ) : (
                                                        <Fragment>
                                                            <button
                                                                className="uk-button uk-button-default uk-button-small"
                                                                type="button"
                                                            >
                                                                관리자 메모
                                                            </button>
                                                            <div data-uk-drop="mode: click">
                                                                <div className="uk-card uk-card-body uk-card-default uk-height-90">
                                                                    <MemoForm orderSeq={order.seq} />
                                                                </div>
                                                            </div>
                                                        </Fragment>
                                                    )
                                                }

                                            </div>
                                        </div>
                                    )
                                }
                            </div>

                        </td>
                    </Fragment>

                )
            }
            {
                nowTab === 7 && (
                    detail.returnInfo ? (
                        <Fragment>
                            <td className="uk-text-nowrap uk-text-0_9">
                                <div className="uk-overflow-auto">
                                    <ul className="uk-list">
                                        <li>
                                            <span>클레임요청일 : {detail.returnInfo.claimRequestDate ? detail.returnInfo.claimRequestDate.substring(0, 16) : '정보가 존재하지 않습니다.'}</span>
                                        </li>
                                        <li>
                                            <span>클레임처리 상태 : {detail.returnInfo.claimStatus ? detail.returnInfo.claimStatus : '정보가 존재하지 않습니다.'}</span>
                                        </li>
                                        <li>
                                            <span>수거 택배사 : {detail.returnInfo.collectDeliveryCompany ? detail.returnInfo.collectDeliveryCompany : '정보가 존재하지 않습니다.'}</span>
                                        </li>
                                        <li>
                                            <span>수거 상태 : {detail.returnInfo.collectStatus ? detail.returnInfo.collectStatus : '정보가 존재하지 않습니다.'}</span>
                                        </li>
                                        <li>
                                            <span onClick={() => window.open(`https://trace.cjlogistics.com/next/tracking.html?wblNo=${detail.returnInfo.collectTrackingNumber}`, "_blank", "width = 750, height = 500, top = 100, left = 200")}>
                                                수거 송장 번호 : {detail.returnInfo.collectTrackingNumber}
                                            </span>
                                        </li>
                                        <li>
                                            <span>
                                                반품 사유 : {`${detail.returnInfo.returnReason ? detail.returnInfo.returnReason : ''} : ${detail.returnInfo.returnDetailedReason ? detail.returnInfo.returnDetailedReason : '정보가 존재하지 않습니다.'}`}
                                            </span>
                                        </li>
                                    </ul>
                                    <hr/>
                                    {
                                        detail.returnInfo.collectAddress && (
                                            <Fragment>
                                                <h4>수거지 주소</h4>
                                                <span>{detail.returnInfo.collectAddress.name}</span> /
                                                <span>{detail.returnInfo.collectAddress.tel1}</span>
                                                <div>
                                                    <span>{detail.returnInfo.collectAddress.baseAddress}</span>
                                                    <br/>
                                                    <span>{detail.returnInfo.collectAddress.detailedAddress}</span>
                                                    <br/>
                                                    <span>우) {detail.returnInfo.collectAddress.zipCode}</span>
                                                </div>
                                            </Fragment>
                                        )
                                    }
                                </div>
                            </td>
                            <td className="uk-text-nowrap uk-text-0_9">
                                {
                                    detail.returnInfo ? (
                                        <ul className="uk-list">
                                            <li>
                                                <span>총 결제 금액 : {order.price.toLocaleString()}원</span>
                                            </li>
                                            <li>
                                                <span>반품 왕복 배송비 : {detail.returnInfo.claimDeliveryFeeDemandAmount ? detail.returnInfo.claimDeliveryFeeDemandAmount.toLocaleString() : 0}원</span>
                                            </li>
                                            <li>
                                                <span>반품 추가 기타 금액 : {detail.returnInfo.etcFeeDemandAmount ? detail.returnInfo.etcFeeDemandAmount.toLocaleString() : 0}원</span>
                                            </li>
                                            <li>
                                                <span>반품 배송비 결제 방법 : {detail.returnInfo.claimDeliveryFeePayMethod ? detail.returnInfo.claimDeliveryFeePayMethod : '정보가 존재하지않습니다.'}</span>
                                            </li>
                                            <li>
                                                <span>총 반품 금액 : {(order.eachPrice.returned - (order.eachPrice.returnDeliveryPrice + order.eachPrice.returnEtcPrice)).toLocaleString()}원</span>
                                            </li>
                                        </ul>
                                    ) : <span>반품정보가 존재하지 않습니다.</span>
                                }
                            </td>
                        </Fragment>
                    ) : (
                        <td>
                            <span>반품정보가 존재하지 않습니다.</span>
                        </td>
                    )
                )
            }
            {
                nowTab === 6 && (
                    detail.cancelInfo ? (
                        <td className="uk-text-nowrap uk-text-0_9">
                            <div className="uk-overflow-auto">
                                <ul className="uk-list">
                                    {
                                        detail.cancelInfo.claimRequestDate && (
                                            <li><span>취소요청일 : {detail.cancelInfo.claimRequestDate.substring(0, 16)}</span></li>
                                        )
                                    }
                                    {
                                        detail.cancelInfo.cancelReason && (
                                            <li className="uk-margin-3-top"><span>취소사유 : {detail.cancelInfo.cancelReason}</span></li>
                                        )
                                    }
                                    {
                                        detail.cancelInfo.cancelDetailedReason && (
                                            <li className="uk-margin-3-top"><span>상세사유 : {detail.cancelInfo.cancelDetailedReason}</span></li>
                                        )
                                    }
                                    <li className="uk-margin-3-top">
                                        <span>취소 완료 금액 : {(detail.price * detail.qty).toLocaleString()}원</span>
                                    </li>

                                </ul>
                            </div>

                        </td>

                    ) :
                    <td>
                        <span>취소정보가 존재하지않습니다.</span>
                    </td>
                )
            }
            {
                detailIndex > 0 === false && order.eachPrice && (
                    <Fragment>
                        <td rowSpan={order.detail.length} className="uk-text-nowrap uk-text-0_9">
                            <div className="uk-overflow-auto">
                                <ul className="uk-list">
                                    <li>
                                        <span>상품 총 금액 : {order.eachPrice.totalPrice.toLocaleString()}원</span>
                                    </li>
                                    <li className="uk-margin-3-top">
                                        <span>배송비 : {order.eachPrice.deliveryPrice.toLocaleString()}원</span>
                                    </li>
                                    <li className="uk-margin-3-top">
                                        <span>도서산간 추가 배송비 : {order.eachPrice.addDeliveryPrice.toLocaleString()}원</span>
                                    </li>
                                    <li className="uk-margin-3-top">
                                        <span>취소 금액 : {order.eachPrice.canceledPrice.toLocaleString()}원</span>
                                    </li>
                                    <li className="uk-margin-3-top">
                                        <span>반품 금액 : {order.eachPrice.returned.toLocaleString()}원</span>
                                    </li>
                                    <li className="uk-margin-3-top">
                                        <span>총 결제 금액 : {order.eachPrice.calculatedPrice.toLocaleString()}원</span>
                                    </li>
                                    <li className="uk-margin-3-top">
                                        <span>결제방법 : {getPayType(order)}</span>
                                    </li>
                                </ul>
                            </div>
                        </td>
                    </Fragment>

                )
            }

        </tr>
    )
};

export default ItemStyle;
