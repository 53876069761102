import React, { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import ActionLoader from '../../Widget/ActionLoader';
import UIkit from 'uikit';
import { updateOrderAction } from '../../../reducers/order';

const DeliveryForm = (order, isUserModal=0) => {
	const dispatch = useDispatch();
	const cancelBtn = useRef();
	const {
		updateOrderDone,
		updateOrderLoading
	} = useSelector((state) => state.order);

	const [isUpdate, setIsUpdate] =  useState(false);
	const [invoice, setInvoice] = useState(order.invoice ? order.invoice : "");
	const [parcelCompany, setParcelCompany] = useState(order.parcelCompany ? order.parcelCompany : "");

	const ParcelCompanyList = [
		{name:"선택",value:""},
		{name:"CJ대한통운",value:"cj"},
		{name:"한진택배",value:"hanjin"},
	];
	
	const onClickIsUpdate = useCallback(() => {
		setIsUpdate(!isUpdate);
	},[isUpdate]);

	const onSubmitInvoice = useCallback((e) => {
		e.preventDefault();
		const formData = new FormData();
		formData.append("invoice", invoice);
		formData.append("parcelCompany", parcelCompany);
		formData.append("_method", "PUT");
		dispatch(updateOrderAction(formData, order.seq, isUserModal));
	}, [invoice, order.seq, parcelCompany]);

	useEffect(() => {
		if(isUpdate && updateOrderDone){
			cancelBtn.current.click();
			setIsUpdate(false);
		}
	}, [updateOrderDone]);
	
	return (
		<Fragment>
			<span>{order.parcelCompanyName}</span>
			<br/>
			{
				order.invoice && (
					<span 
						className="uk-pointer"
						onClick={() => window.open(parcelCompany==='cj' ? `https://trace.cjlogistics.com/next/tracking.html?wblNo=${order.invoice}` : `http://www.hanjinexpress.hanjin.net/customer/hddcw18.tracking?w_num=${order.invoice}`, "_blank", "width = 750, height = 500, top = 100, left = 200")}
					>
						{order.invoice}
					</span>
				)
			}
			{
				((order.status == "2" || order.status == "3" || order.status == "4") &&
				(order.payType == "1" || order.payType == "2" || order.payType == "4")) && (
					<div className="uk-margin-small-top">
						<Fragment>
							<button
								className="uk-button uk-button-default uk-button-small"
								type="button"
							>
								송장 {(order.invoice) ? "관리" : "등록"}
							</button>
							<div className={`invoiceModal_${order.seq}`} data-uk-drop="mode: click">
								<div className="uk-card uk-card-body uk-card-default uk-height-90">
									{
										updateOrderLoading ? (
											<ActionLoader />
										) : (
											<form onSubmit={onSubmitInvoice}>
												<select
													className="uk-select uk-width-100 uk-height-30"
													value={parcelCompany}
													onChange={(e) => setParcelCompany(e.target.value)}
												>
													{
														ParcelCompanyList.map((data, index) => (
															<option key={index} value={data.value}>{data.name}</option>
														))
													}
												</select>

												<input
													type="text"
													className="uk-input uk-width-140 uk-height-30 uk-margin-10-left"
													placeholder="송장번호"
													value={invoice}
													onChange={(e) => setInvoice(e.target.value)}
												/>
												
												<div className="uk-text-right">
													<button className="uk-button uk-button-text uk-margin-small-right uk-drop-close" ref={cancelBtn} type="button">취소</button>
													<button className="uk-button uk-button-text uk-text-primary" onClick={onClickIsUpdate} type="submit">저장</button>
												</div>
											</form>
										)
									}
								</div>
							</div>
						</Fragment>
					</div>
				)
			}
		</Fragment>
	)
};

export default DeliveryForm;
