import React, { useEffect, useCallback, useState, Fragment } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import PropTypes from 'prop-types';

import {
    loadOrdersAction,
    updateOrderDetailAction,
    loadOrdersResetAction,
    updateOrderDetailResetAction
} from '../../../reducers/order';
import { loadCountsForSidenavAction } from '../../../reducers/analytics';

import { loadGetOrderDetailCountByStatusAction } from "../../../actions/order/action";

import { useFormData } from '../../../hook';
import UseTwoDepthCheckboxForm from '../../../hook/UseTwoDepthCheckboxForm';
import { phoneFormat } from '../../../utils';

import UIKit from "../../../Components/UIKit";
import OrderHeaderStyle from '../Order/OrderHeaderStyle';
import Pagination from "../Pagination";
import Loader  from '../Loader';
import MemoForm from './MemoForm';
import MemoFormTextAreaStyle from './MemoFormTextAreaStyle';
import UserModal from '../User/UserModal';
import OrderModal from './OrderModal';

const CancelTableStyle = ({ payType, payMethod }) => {
    const dispatch = useDispatch();
    const {
        orders,
        loadOrdersLoading,
        loadOrdersDone,
        loadOrdersError,
        updateOrderDetailLoading,
        updateOrderDetailDone,
        updateOrderDetailError,
        updateOrderDetail,
        loadGetOrderDetailCountByStatus,
    } = useSelector((state) => state.order);

    const { selectedBrand } = useSelector((state) => state.user);

    const [openUserModal, setOpenUserModal] = useState(false);
    const [targetUserSeq, setTargetUserSeq] = useState("");
    const [openOrderModal, setOpenOrderModal] = useState(false);
    const [targetOrderSeq, setTargetOrderSeq] = useState("");
    const [nowTab, setNowTab] = useState(2);

    const twoDepthCheckboxForm = UseTwoDepthCheckboxForm({});
    const history = useHistory();
    const location = useLocation();

    const locationSearch = location.search;
    const parsedLocationSearch = queryString.parse(locationSearch);

    let defaultColumn = '';
    if(parsedLocationSearch.sort) {
        defaultColumn = JSON.parse(atob(parsedLocationSearch.sort));
        defaultColumn = Object.keys(defaultColumn)[0];
    }

    const getPayType = (order) => {
      if(order.payType === 2) {
          return "네이버 페이";
      } else if(order.payType === 3) {
          return "아임웹"
      }else{
          if(order.payLog && order.payLog.result) {
              return order.payLog.result.payMethodName;
          } else {
              return "알수없음";
          }
      }
  }

    const getPayIcon = useCallback((payType) => {
      if(payType === 2) {
          return (
              <img src="/image/naver_simple.png" className="uk-object-cover uk-width-15 uk-height-auto uk-margin-xsmall-right uk-text-top uk-margin-4-top" />
          );
      } else if(payType === 3) {
          return (
              <img src="/image/imweb.png" className="uk-object-cover uk-width-15 uk-height-auto uk-margin-xsmall-right uk-text-top uk-margin-4-top" />
          );
      }
      return null;

  }, []);

    useEffect(() => {
        return () => {
            dispatch(loadOrdersResetAction());
        }
    }, []);

    useEffect(() => {
        let query = location.search;
        const parsedQuery = queryString.parse(query);
        let status = 3;
        if(parsedQuery.status) {
            status = parsedQuery.status;
            if(status !== 'all') {
                status = parseInt(status, 10);
            }
            setNowTab(status);
        }
        dispatch(loadOrdersAction(`/details${query}&payMethod=${payMethod}`));
        if(payMethod === 'CARD') {
            dispatch(loadGetOrderDetailCountByStatusAction(3));
        } else {
            dispatch(loadGetOrderDetailCountByStatusAction(4));
        }

    }, [location, selectedBrand]);

    useEffect(() => {
        if(loadOrdersError) {
            alert(loadOrdersError.msg);
        }
    }, [loadOrdersError]);

    useEffect(() => {
        if(loadOrdersDone) {
            twoDepthCheckboxForm.init(orders);
        }
    }, [loadOrdersDone]);

    const onSubmitOrderDetailStatus = useCallback((orderStatus) => {
        const copyTwoDepthCheckboxFormValue = JSON.parse(JSON.stringify(twoDepthCheckboxForm.value)); //깊은복사
        delete copyTwoDepthCheckboxFormValue.total;
        delete copyTwoDepthCheckboxFormValue.isShowDetailOption;


        // 서버에 호출하기전 체크박스 체크안된 주문,주문상세 전부 제거
        for(let copyParentCheckboxKey of Object.keys(copyTwoDepthCheckboxFormValue)) {
            if(copyTwoDepthCheckboxFormValue[copyParentCheckboxKey].checked === false) {
                delete copyTwoDepthCheckboxFormValue[copyParentCheckboxKey];
                continue;
            }

            for(let copyChildCheckboxKey of Object.keys(copyTwoDepthCheckboxFormValue[copyParentCheckboxKey].detailCheckbox)) {
                if(copyTwoDepthCheckboxFormValue[copyParentCheckboxKey].detailCheckbox[copyChildCheckboxKey].checked === false) {
                    delete copyTwoDepthCheckboxFormValue[copyParentCheckboxKey].detailCheckbox[copyChildCheckboxKey];
                }
            }
        }

        if(Object.keys(copyTwoDepthCheckboxFormValue).length <= 0){
          alert('처리할 주문을 선택해 주세요.');
        }else{
          dispatch(updateOrderDetailAction('admin/orders/details', {data: JSON.stringify(copyTwoDepthCheckboxFormValue), status: orderStatus}));
        }
        // if(payType === 1) {
        //     dispatch(updateOrderDetailAction('admin/orders/details', {data: JSON.stringify(copyTwoDepthCheckboxFormValue), status: orderStatus}));
        // } else if(payType === 2) {
        //     dispatch(updateOrderDetailAction('admin/orders/details/cancelApplyCancel', {data: JSON.stringify(copyTwoDepthCheckboxFormValue), status: orderStatus}));
        // }
    }, [twoDepthCheckboxForm, payType]);

    useEffect(() => {
        if(updateOrderDetailDone) {
            let query = location.search;
            dispatch(loadOrdersAction(`/details${query}&payMethod=${payMethod}`));
            dispatch(loadCountsForSidenavAction());
            alert(updateOrderDetail.msg);
            dispatch(updateOrderDetailResetAction());
        }

        if(updateOrderDetailError) {
            alert(updateOrderDetailError.msg);
            dispatch(updateOrderDetailResetAction());
        }
    }, [updateOrderDetailDone, updateOrderDetailError]);

    const onClickOpenUserModal = useCallback((userSeq) => {
        setTargetUserSeq(userSeq);
        setOpenUserModal(true);
    }, []);

    const onClickOpenOrderModal = useCallback((orderSeq) => {
        setTargetOrderSeq(orderSeq);
        setOpenOrderModal(true);
    }, []);

    const getDeliveryPrice = useCallback((order, detail) => {
        let cancelPrice = 0;
        let deliveryPrice = 0;
        for(let orderDetail of order.detail) {
            cancelPrice += (orderDetail.price * orderDetail.qty);
        }
        if(detail.cancelInfo && detail.cancelInfo.deliveryFeeAmount) {
            if(cancelPrice > order.amountDeposited) {
                deliveryPrice = detail.cancelInfo.deliveryFeeAmount;
            }
        }
        return deliveryPrice;
    }, []);

    const getAddDeliveryPrice = useCallback((order, detail) => {
        let cancelPrice = 0;
        let addDeliveryPrice = 0;
        for(let orderDetail of order.detail) {
            cancelPrice += (orderDetail.price * orderDetail.qty);
        }
        if(detail.cancelInfo && detail.cancelInfo.sectionDeliveryFee) {
            if(cancelPrice > order.amountDeposited) {
                addDeliveryPrice = detail.cancelInfo.sectionDeliveryFee;
            }
        }
        return addDeliveryPrice;
    }, []);

    const onClickCopyClipBoard = useCallback((e) => {
        const text = window.document.createElement('textarea');
        window.document.body.appendChild(text);
        text.value = e.target.innerText;
        text.focus();
        text.select();
        text.setSelectionRange(0, 99999);
        const isCopy = document.execCommand('copy');
        if(isCopy) {
            alert('복사되었습니다.');
        } else {
            alert('복사실패했습니다. 관리자에게 문의하여 주세요.');
        }
        document.body.removeChild(text);
    }, []);

    const onClickTab = (status) => {
        let query=`page=1&status=${status}&payMethod=${payMethod}`;
        setNowTab(status);
        history.push({
            pathname: location.pathname,
            search: query
        });
    }

    return loadOrdersLoading ? (
        <div>
            <Loader />
        </div>
    ) : loadOrdersDone && (
        <div>
            <UIKit.Div classOptions={"uk-width-1-1"}>
                <OrderHeaderStyle />
            </UIKit.Div>
            <div>
                <ul data-uk-tab>
                    <li className={nowTab === 2 ? "uk-active" : ""} onClick={() => onClickTab(2)}>
                        <a>
                            <span className="uk-margin-small-right">취소요청</span>
                            <span className="uk-badge">{loadGetOrderDetailCountByStatus && loadGetOrderDetailCountByStatus.requestCancelCount ? loadGetOrderDetailCountByStatus.requestCancelCount.toLocaleString() : 0}</span>
                        </a>
                    </li>
                    <li className={nowTab === 5 ? "uk-active" : ""} onClick={() => onClickTab(5)}>
                        <a>
                            <span className="uk-margin-small-right">취소완료</span>
                            <span className="uk-badge">{loadGetOrderDetailCountByStatus && loadGetOrderDetailCountByStatus.completedCancelCount ? loadGetOrderDetailCountByStatus.completedCancelCount.toLocaleString() : 0}</span>
                        </a>
                    </li>
                </ul>
            </div>
            <div>
                <div className="uk-card uk-card-default uk-card-body uk-card-small uk-padding-remove">
                    <div data-uk-grid>
                        <div className="uk-width-2-3">
                            {
                                updateOrderDetailLoading ? (
                                    <button
                                        className="uk-button uk-button-default uk-button-small"
                                    >
                                        <div data-uk-spinner></div>
                                    </button>
                                ) : (
                                    <button
                                        className="uk-button uk-button-default uk-button-small"
                                        uk-tooltip="주문을 취소완료로 변경합니다."
                                        onClick={() => onSubmitOrderDetailStatus(5)}
                                    >
                                        취소완료
                                    </button>
                                )
                            }
                            {
                                payType === 2 && (
                                    <button
                                        className="u k-button uk-button-default uk-button-small uk-hidden"
                                        uk-tooltip="주문을 취소거부하여 배송중으로 변경합니다."
                                        onClick={() => onSubmitOrderDetailStatus(1)}
                                    >
                                        취소거절
                                    </button>
                                )
                            }

                        </div>
                    </div>
                    <div className="uk-overflow-auto uk-margin-top uk-panel-scrollable@m uk-border-none uk-padding-remove uk-resize-none" data-uk-height-viewport="offset-top: true; offset-bottom:120px;">
                        <table className="uk-table uk-table-divider uk-table-small uk-table-border uk-overflow-auto">
                            <thead className="uk-text-0_8 uk-text-center">
                                <tr>
                                    <th className="uk-text-left uk-width-215">
                                        <input
                                            type="checkbox"
                                            className="uk-checkbox uk-margin-small-right"
                                            onChange={twoDepthCheckboxForm.onChangeCheckboxAll}
                                            checked={twoDepthCheckboxForm.value.total && twoDepthCheckboxForm.value.total.checked ? twoDepthCheckboxForm.value.total.checked : false}
                                        />
                                        <span>주문번호</span>
                                    </th>
                                    <th className="uk-text-nowrap uk-table-expand">주문상품</th>
                                    <th className="uk-table-shrink uk-text-nowrap">상품금액</th>
                                    <th className="uk-table-shrink uk-text-nowrap">수량</th>
                                    <th className="uk-table-shrink uk-text-nowrap">상태</th>
                                    <th className="uk-table-shrink uk-text-nowrap">배송</th>
                                    <th className="uk-width-auto uk-min-width-200">배송정보</th>
                                    {
                                        nowTab === 2 ? (
                                          <th className="uk-width-auto uk-min-width-200">취소 정보</th>
                                        ) : null
                                    }
                                    <th className="uk-table-shrink uk-text-nowrap">결제내역</th>
                                </tr>
                            </thead>
                            <tbody className="uk-text-0_8">
                                {
                                    orders && Object.keys(orders).length > 0 ? (
                                        orders.data.map((order,index) => ((
                                                order.detail.map((detail, detailIndex) => (
                                                    <tr key={detail.seq} className={index%2==0 ? "uk-background-gray5" : ""}>
                                                        {
                                                            detailIndex > 0 === false && (
                                                                <Fragment>
                                                                    <td rowSpan={order.detail.length} className="uk-width-215 uk-text-nowrap">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="uk-checkbox uk-text-top uk-margin-4-top uk-margin-small-right"
                                                                            onChange={() => twoDepthCheckboxForm.first(order.seq)}
                                                                            checked={twoDepthCheckboxForm.value[order.seq] && twoDepthCheckboxForm.value[order.seq].checked ? twoDepthCheckboxForm.value[order.seq].checked : false}
                                                                        />
                                                                        <div className="uk-display-inline-block">
                                                                            <p
                                                                                className="uk-margin-remove uk-text-bold uk-pointer uk-text-0_95"
                                                                                onClick={onClickCopyClipBoard}
                                                                            >
                                                                                {order.seq}

                                                                            </p>
                                                                            <p className="uk-margin-remove"><span className="uk-display-inline-block uk-text-0_8 uk-vertical-top">{order.regdate.substring(0, 16)}</span><span className="uk-display-inline-block uk-text-0_9 uk-margin-xsmall-left uk-vertical-top">({order.brand.name})</span></p>
                                                                            {
                                                                                order.buyer &&order.buyer.name && (
                                                                                    <p className="uk-margin-small-top uk-margin-remove-bottom">
                                                                                        {
                                                                                            getPayIcon(order.payType)
                                                                                        }
                                                                                        <span
                                                                                            className="uk-pointer"
                                                                                            onClick={() => order.user_seq === 0 ? onClickOpenOrderModal(order.seq) : onClickOpenUserModal(order.user_seq)}
                                                                                        >
                                                                                            {order.buyer.name}
                                                                                            {order.payType !== 2 && order.payType !== 3 && order.user_seq === 0 && "(비회원)"}
                                                                                        </span>
                                                                                    </p>
                                                                                )
                                                                            }
                                                                            <p className="uk-margin-small-top uk-margin-remove-bottom uk-text-success">{order.statusName}</p>
                                                                        </div>
                                                                    </td>
                                                                </Fragment>
                                                            )
                                                        }
                                                        <td rowSpan={1} className="uk-text-nowrap">
                                                            <div className="uk-overflow-auto">
                                                                <div className="uk-display-inline-block uk-vertical-top">
                                                                    <input
                                                                        type="checkbox"
                                                                        className="uk-checkbox uk-margin-remove-top uk-vertical-middle"
                                                                        onChange={() => twoDepthCheckboxForm.second(order.seq, detail.seq)}
                                                                        checked={
                                                                            twoDepthCheckboxForm.value[order.seq] &&
                                                                            twoDepthCheckboxForm.value[order.seq].detailCheckbox[detail.seq] &&
                                                                            twoDepthCheckboxForm.value[order.seq].detailCheckbox[detail.seq].checked ?
                                                                            twoDepthCheckboxForm.value[order.seq].detailCheckbox[detail.seq].checked :
                                                                            false
                                                                        }
                                                                    />
                                                                    <div className="uk-display-inline-block uk-margin-3-left">
                                                                      <a href={order.brand.domain+"/product/all/"+detail.product?.seq} target="_blank">
                                                                          <img
                                                                              className="uk-preserve-width uk-border-circle"
                                                                              src={detail.product?.img[0]}
                                                                              width={"40"}
                                                                              alt=""
                                                                          />
                                                                      </a>
                                                                    </div>
                                                                </div>
                                                                <div className="uk-display-inline-block uk-vertical-top uk-margin-xsmall-left">
                                                                    <span className={detail.product?.name === undefined ? 'uk-text-danger' : ""}>{detail.product?.name === undefined ? "삭제된 상품" : detail.product.name}</span>
                                                                    <br/>
                                                                    <span>{detail.option_name}</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td rowSpan={1}>
                                                          <div className="uk-overflow-auto uk-text-center uk-text-nowrap">
                                                              {(detail.price).toLocaleString()}원
                                                          </div>
                                                        </td>
                                                        <td rowSpan={1}>
                                                          <div className="uk-overflow-auto uk-text-center uk-text-nowrap">
                                                              {detail.qty}개
                                                          </div>
                                                        </td>
                                                        <td rowSpan={1}>
                                                          <div className="uk-overflow-auto uk-text-center uk-text-nowrap">
                                                            {detail.statusName}
                                                          </div>
                                                        </td>
                                                        {
                                                            detailIndex > 0 === false && (
                                                                <Fragment>
                                                                    <td rowSpan={order.detail.length}>
                                                                      <div className="uk-overflow-auto uk-text-center uk-text-nowrap">
                                                                          <span>{order.parcelCompanyName}</span>
                                                                          <br/>
                                                                          {
                                                                              order.invoice && (
                                                                                <span 
                                                                                className="uk-pointer"
                                                                                onClick={() => window.open(order.parcelCompany==='cj' ? `https://trace.cjlogistics.com/next/tracking.html?wblNo=${order.invoice}` : `http://www.hanjinexpress.hanjin.net/customer/hddcw18.tracking?w_num=${order.invoice}`, "_blank", "width = 750, height = 500, top = 100, left = 200")}
                                                                              >
                                                                                {order.invoice}
                                                                              </span>
                                                                              )
                                                                          }
                                                                        </div>
                                                                    </td>
                                                                    <td rowSpan={order.detail.length} className="uk-text-0_9">
                                                                      <div className="uk-overflow-auto">
                                                                        {
                                                                            order.delivery && (
                                                                                <div>
                                                                                    <span>{order.delivery.name}</span> / <span>{phoneFormat(order.delivery.phone)}</span>
                                                                                    <div className="uk-margin-3-top">
                                                                                        <span>{order.delivery.address}</span>
                                                                                        <br/>
                                                                                        <span>{order.delivery.addressDetail}</span>
                                                                                        <br/>
                                                                                        <span>우) {order.delivery.postcode}</span>
                                                                                    </div>
                                                                                    <div>
                                                                                        {
                                                                                            order.delivery.message && (
                                                                                              <div className="uk-margin-3-top">
                                                                                                <span>[배송메시지]</span>
                                                                                                <br />
                                                                                                <span>{order.delivery.message}</span>
                                                                                              </div>
                                                                                            )

                                                                                        }

                                                                                        {
                                                                                            (detail.status === 5 || detail.status === 2) && (
                                                                                                <div className="uk-margin-3-top">
                                                                                                    <span>[취소 사유]</span>
                                                                                                    <br />
                                                                                                    <span>{detail.cancelReason}</span>
                                                                                                </div>
                                                                                            )
                                                                                        }
                                                                                        <br/>
                                                                                        {
                                                                                            order.returnInfo && (
                                                                                                <ul className="uk-list uk-margin-3-top">
                                                                                                    {order.returnInfo.bankName &&  (
                                                                                                        <li>은행명 : {order.returnInfo.bankName}</li>
                                                                                                    )}
                                                                                                    {order.returnInfo.bankNum && (
                                                                                                        <li>계좌번호 : {order.returnInfo.bankNum}</li>
                                                                                                    )}
                                                                                                    {order.returnInfo.accountHolder && (
                                                                                                        <li>예금주 : {order.returnInfo.accountHolder}</li>
                                                                                                    )}
                                                                                                </ul>
                                                                                            )
                                                                                        }
                                                                                        <br/>
                                                                                    </div>
                                                                                    <div className="uk-margin-small-top">
                                                                                        {
                                                                                            order.memo ? (
                                                                                                <MemoFormTextAreaStyle orderSeq={order.seq} orderMemo={order.memo} />
                                                                                            ) : (
                                                                                                <Fragment>
                                                                                                    <button
                                                                                                        className="uk-button uk-button-default uk-button-small"
                                                                                                        type="button"
                                                                                                    >
                                                                                                        관리자 메모
                                                                                                    </button>
                                                                                                    <div data-uk-drop="mode: click">
                                                                                                        <div className="uk-card uk-card-body uk-card-default">
                                                                                                            <MemoForm orderSeq={order.seq} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </Fragment>
                                                                                            )
                                                                                        }

                                                                                    </div>

                                                                                </div>
                                                                            )
                                                                        }
                                                                      </div>
                                                                    </td>
                                                                    <OrderModal openOrderModal={openOrderModal} setOpenOrderModal={setOpenOrderModal} orderSeq={order.seq} />
                                                                </Fragment>

                                                            )
                                                        }
														{
															detail.cancelInfo ? (
																nowTab === 2 ? (
																	<td className="uk-text-nowrap uk-text-0_9">
                                    <div className="uk-overflow-auto">
                                      <ul className="uk-list">
                                        {
                                          detail.cancelInfo.claimRequestDate && (
                                            <li><span>취소요청일 : {detail.cancelInfo.claimRequestDate.substring(0, 16)}</span></li>
                                          )
                                        }
                                        {
                                          detail.cancelInfo.cancelReason && (
                                            <li className="uk-margin-3-top"><span>취소사유 : {detail.cancelInfo.cancelReason}</span></li>
                                          )
                                        }
                                        {
                                          detail.cancelInfo.cancelDetailedReason && (
                                            <li className="uk-margin-3-top"><span>상세사유 : {detail.cancelInfo.cancelDetailedReason}</span></li>
                                          )
                                        }
                                        <li className="uk-margin-3-top">
                                          <span>추가 배송비 : {getDeliveryPrice(order, detail).toLocaleString()}원</span>
                                        </li>
                                        <li className="uk-margin-3-top">
                                          <span>도서산간 추가 배송비 : {getAddDeliveryPrice(order, detail).toLocaleString()}원</span>
                                        </li>
                                        <li className="uk-margin-3-top">
                                          <span>취소 요청 금액 : {(detail.price * detail.qty).toLocaleString()}원</span>
                                        </li>
                                      </ul>
                                    </div>
																	</td>
																) : (
																		detailIndex > 0 === false && (
																			<td rowSpan={order.detail.length} className="uk-text-nowrap uk-text-0_9">
                                        <div className="uk-overflow-auto">
                                          <ul className="uk-list">
                                            <li>
                                              <span>상품 총 금액 : {order.price.toLocaleString() }원</span>
                                            </li>
                                            <li className="uk-margin-3-top">
                                              <span>배송비 : {order.status === 6 && order.eachPrice.isDeliveryPriceAfterPartialCancel ? 0 : order.eachPrice.deliveryPrice.toLocaleString()}원</span>
                                            </li>
                                            <li className="uk-margin-3-top">
                                              <span>도서산간 배송비 : {order.status === 6 && order.eachPrice.isDeliveryPriceAfterPartialCancel ? 0 : order.eachPrice.addDeliveryPrice.toLocaleString()}원</span>
                                            </li>
                                            <li className="uk-margin-3-top">
                                              <span>취소/환불 금액 : {(order.eachPrice.canceledPrice + order.eachPrice.returned + (order.status === 6 ? (order.eachPrice.isDeliveryPriceAfterPartialCancel ? 0 : order.eachPrice.deliveryPrice+order.eachPrice.addDeliveryPrice) : 0 )).toLocaleString()}원</span>
                                            </li>
                                            <li className="uk-margin-3-top">
                                              <span>반품왕복 배송비 : {order.eachPrice.returnDeliveryPrice.toLocaleString()}원</span>
                                            </li>
                                            <li className="uk-margin-3-top">
                                              <span>추가 기타 반품 금액 : {order.eachPrice.returnEtcPrice.toLocaleString()}원</span>
                                            </li>
                                            <li className="uk-margin-3-top">
                                              <span>최종 결제 금액 : {(order.eachPrice.calculatedPrice>0 ? (order.eachPrice.calculatedPrice - (order.eachPrice.isDeliveryPriceAfterPartialCancel ? order.eachPrice.deliveryPrice+order.eachPrice.addDeliveryPrice : 0)) : 0).toLocaleString()}원</span>
                                            </li>
                                            <li className="uk-margin-3-top">
                                              <span>결제방법 : {getPayType(order)}</span>
                                            </li>
                                          </ul>
                                        </div>
																			</td>
																		)
																)

															) : (
																<td>
																	<span>취소정보가 존재하지않습니다.</span>
																</td>
															)
														}
                                                        {
                                                            detailIndex > 0 === false && nowTab === 2 ? (
                                                                <Fragment>
                                                                    <td rowSpan={order.detail.length} className="uk-text-nowrap uk-text-0_9">
                                                                        {
                                                                                order.payType !== 2 && order.payType !== 3 && order.payLog && (
                                                                                    <span className="uk-text-danger">
                                                                                        {order.payLog.result.payMethod === 'VBANK' ? '입금해야 할 금액 :' : '취소예정 금액 :'}
                                                                                        {order.amountDeposited.toLocaleString()}원
                                                                                    </span>
                                                                                )
                                                                        }
                                                                        <br/>
                                                                        <span>결제방법 : {getPayType(order)}</span>
                                                                    </td>
                                                                </Fragment>

                                                            ) : null
                                                        }

                                                    </tr>
                                                ))
                                            ))
                                        )
                                    ) : (
                                        <tr>
                                            <td colSpan={14} className="uk-text-center">목록이 존재하지않습니다.</td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
                <div className="uk-margin-top">
                    <Pagination
                        currentPage={orders.current_page ? orders.current_page : 1}
                        pageLast={orders.last_page ? orders.last_page : 1}
                    />
                </div>
                <UserModal openUserModal={openUserModal} setOpenUserModal={setOpenUserModal} userSeq={targetUserSeq} />
                <OrderModal openOrderModal={openOrderModal} setOpenOrderModal={setOpenOrderModal} orderSeq={targetOrderSeq} />
            </div>


        </div>
    )
};

CancelTableStyle.propTypes = {
    payType: PropTypes.number.isRequired,
};

export default CancelTableStyle;
